.button-scroll {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 4px;
  text-align: center;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 11px;
    background-color: $color-gray-bg;
  }

  .arrow {
    position: relative;
    padding: 0 24px 0 56px;
    background: $color-white;
    font-size: 16px;
    line-height: 1.33;
    font-family: $nndagny-display-normal;
    z-index: 1;

    .svg-icon {
      position: absolute;
      top: -5px;
      left: 26px;
      transition: .2s;
    }
  }

  &:hover {

    .arrow {

      .svg-icon {
        transform: translateY(2px);
      }
    }
  }
}