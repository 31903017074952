 .gift-with-counter {

  @include breakpoint(xxlarge up) {
    display: flex;
    justify-content: space-between;
  }

  .counter-side {
    margin-bottom: 24px;

    @include breakpoint(xxlarge up) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
    }

    .counter-intro {
      margin-bottom: 24px;
    }

    .counter {
      margin-bottom: 0 !important;
    }
  }

  .card-side {

    @include breakpoint(xxlarge up) {
      min-width: 250px;
      margin-left: 24px;
    }
  }
}