.buttons-group {
  @include breakpoint(medium up) {
    display: flex;
    align-items: center;
  }

  .button-copy {
    @include breakpoint(small only) {
      display: block;
    }
  }

  .btn-group-item {
    @include breakpoint(small only) {
      margin-bottom: 16px;
    }

    &:nth-child(2) {
      @include breakpoint(medium up) {
        margin-left: 32px;
      }
    }

    &:nth-child(3) {
      display: inline-block;
      margin-left: auto;
      margin-right: 0;

      @include breakpoint(small only) {
        margin-bottom: 0;
      }
    }
  }
}
