.container-two-columns {

  @include breakpoint(large up) {
    display: flex;
    gap: var(--gutter-desktop, 32px);

    .col-wrapper {
      width: calc(50% - var(--gutter-desktop, 32px));
    }
  }

  &.ratio-1-2 {
    @include breakpoint(large up) {
      .col-wrapper {
        width: calc(50% - var(--gutter-desktop, 32px));
      }
    }
  }
  
  &.ratio-1-3 {    
    @include breakpoint(large up) {
      .col-wrapper:first-child {
        width: calc(33.3333% - var(--gutter-desktop, 32px));
      }
      .col-wrapper:last-child {
        width: calc(66.6666% - var(--gutter-desktop, 32px));
      }    
    }
  }

  &.ratio-1-4 {
    @include breakpoint(large up) {
      .col-wrapper:first-child {
        width: calc(25% - var(--gutter-desktop, 32px));
      }
      .col-wrapper:last-child {
        width: calc(75% - var(--gutter-desktop, 32px));
      }    
    }
  }

  &.ratio-2-3 {
    @include breakpoint(large up) {
      .col-wrapper:first-child {
        width: calc(66.6666% - var(--gutter-desktop, 32px));
      }
      .col-wrapper:last-child {
        width: calc(33.3333% - var(--gutter-desktop, 32px));
      }    
    }
  }

  &.ratio-3-4 {
    @include breakpoint(large up) {
      .col-wrapper:first-child {
        width: calc(75% - var(--gutter-desktop, 32px));
      }
      .col-wrapper:last-child {
        width: calc(25% - var(--gutter-desktop, 32px));
      }    
    }
  }
}