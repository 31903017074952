/* UTILITIES
--------------------------------------------------------------------------
========================================================================*/

/*
------------------------------------------------------------------------*/

@include breakpoint(large up) {
  .large-offset-right-1 {
    margin-right: 8.33333%;
  }
}

/*
------------------------------------------------------------------------*/

.overflow {
  overflow: hidden !important;
}

.no-border {
  border: 0 !important;
}

.relative {
  position: relative;
}

/* Displays
------------------------------------------------------------------------*/

.display-inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.custom-hide {
  display: none;
}

.hidden {
  visibility: hidden;
}

@media #{$small-only} {
  .small-inline-block {
    display: inline-block;
  }
}

@media #{$medium-only} {
  .medium-inline-block {
    display: inline-block;
  }
}

@include breakpoint(xlarge up) {
  .xlarge-5 {
    flex: 0 0 41.66666%;
    max-width: 41.66666%;
  }
}

@include breakpoint(xxlarge up) {
  .hide-for-xxlarge {
    display: none;
  }

  .xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

/* Text decoration
------------------------------------------------------------------------*/

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.no-decoration {
  text-decoration: none;
}

/* Text align
------------------------------------------------------------------------*/

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media #{$medium-up} {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-right {
    text-align: right;
  }
}

@media #{$large-up} {
  .large-text-left {
    text-align: left;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-right {
    text-align: right;
  }
}

/* Margins
------------------------------------------------------------------------*/

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

@media #{$small-only} {
  .small-no-margin {
    margin: 0 !important;
  }
}
@media #{$small-only} {
  .small-no-margin-bottom {
    margin-bottom: 0 !important;
  }
}

/* Paddings
------------------------------------------------------------------------*/

.no-padding {
  padding: 0 !important;
}

@media #{$small-only} {
  .small-no-padding {
    padding: 0 !important;
  }
}

/* Sizes
------------------------------------------------------------------------*/

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.float-right {
  float: right;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.pt-24 {
  padding-top: 24px;
}
