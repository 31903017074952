.card-icon-shift {
  height: 100%;
  position: relative;
  margin-top: 8px;
  padding: 56px 24px 24px;
  background-color: $color-off-white;
  border-radius: 3px;

  .connector {
    display: none;
  }

  .icon-wrapper {
    position: absolute;
    left: 24px;
    top: -8px;
    width: 48px;
    height: 48px;
    min-width: 48px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    img {
      width: 32px;
      max-width: 32px;
      max-height: 32px;
    }

    .icon-symbol {
      color: $color-white;
      font-size: 20px;
    }

    @include icon-colors;
  }

  @include bg-colors;

  @include breakpoint(large up) {

    .connector {
      display: block;
      width: calc(100% - 48px - 8px + var(--gutter, 16px));
      height: 2px;
      position: absolute;
      top: 16px;
      left: calc(24px + 48px + 4px);
      z-index: 2;
      opacity: 0.7;

      @include icon-colors;
    }
  }

  &.highlight {
    border: 1px solid $color-orange-medium;
  }

  &.large-icon {
    margin-top: 16px;
    padding-top: 72px;

    .icon-wrapper {
      top: -16px;
      width: 64px;
      height: 64px;
      min-width: 64px;

      img {
        width: 42px;
        max-width: 42px;
        max-height: 42px;
      }

      .icon-symbol {
        font-size: 28px;
      }
    }

    .connector {
      width: calc(100% - 64px - 8px + var(--gutter, 16px));
      top: 16px;
      left: calc(24px + 64px + 4px);
    }
  }
}