ul {
  margin-left: 0;
  list-style-type: none;
}

.list-simple {

  &.bullets-black {

    & > .list-item {
      position: relative;
      padding-left: 16px;

      &:before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        top: 7px;
        left: 3px;
        background-color: $color-grey-dark;
        border-radius: 4px;
      }
    }

  }

  &.bullets-orange {

    & > .list-item {
      position: relative;
      padding-left: 16px;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 5px;
        left: 0;
        background-color: $color-orange-dark;
        border-radius: 8px;
      }
    }
  }
}