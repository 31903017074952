.full-photo {
  min-width: 100%;
  height: 220px;
  background-image: var(--full-photo);
  background-size: cover;
  background-position: center;
  background-color: $color-white;
  border-radius: 3px;
  overflow: hidden;

  @include breakpoint(medium up) {
    height: var(--height-desktop);
  }

  &.cut-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.cut-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}