.counter {

  [class*="section-header"] {
    margin-bottom: 8px;
  }

  .counter-wrapper {
    padding: 32px 24px;
    background-color: $color-off-white;
    border-radius: 6px;
    display: flex;
    justify-content: center;

    .counter-item-wrapper {
      text-align: center;
      margin: 0 16px;

      .counter-item-value {
        display: block;
        font-size: 40px;
        line-height: 1.1;
        font-family: NNNittiGrotesk-Heading;
      }

      .counter-item-desc {
        display: block;
        font-size: 20px;
        line-height: 1.4;
      }
    }
  }
}